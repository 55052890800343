/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-17 11:30:25
 * @LastEditors: cuihaopeng cuihaopeng@slodon.cn
 * @LastEditTime: 2024-04-26 17:22:24
 * @FilePath: /boyuan_pc/src/utils/config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 *  项目配置文件
 */
// export const apiUrl = 'https://boyuanpc.slodon.cn/';//接口请求地址
export const apiUrl = 'https://pc.laidiangang.com/';//接口请求地址
export const mUrl = ''//移动端网页地址
export const chatUrl = 'https://im.laidiangang.com';//客服地址
// export const chatUrl = 'https://boyuanim.slodon.cn';//客服地址
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
export const title = '来点钢';//浏览器顶部title
export const gdKey = '';//高德web-js key
export const statShowDebug = false;//是否开启统计的调试

// ** copyright *** slodon *** version-v3.7 *** date-2023-01-07 ***主版本v3.7


