/*
 * @Author: your name
 * @Date: 2020-12-25 19:22:45
 * @LastEditTime: 2021-01-15 13:50:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/router/index.js
 */
import { createRouter, createWebHistory } from 'vue-router'
import { title } from "../utils/config";
import { sldStatEvent, updateStatCommonProperty } from "../utils/stat.js";
import { apiUrl } from '../utils/config'
import { wetChatLogin } from '../utils/common.js'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

function checkLogin(next, to, type) {
	const { loginFlag } = localStorage;

	if (loginFlag) {
		let { memberType } = JSON.parse(localStorage.memberInfo)
		if (type && type.length && (!type.includes(memberType))) {
			next({ name: 'MemberIndex' })
		} else {
			next()
		}
	} else {
		next({ name: 'Login', query: { redirectUrl: encodeURIComponent(to.fullPath) } })
	}

	// loginFlag ? next() : next({ name: 'Login', query: { redirectUrl: encodeURIComponent(to.fullPath) } });
}

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import(/* webpackChunkName: "home" */ '../views/home/Home'),
		redirect: '/index',
		children: [
			{
				path: '/index',
				name: 'HomeIndex',    //首页
				component: () => import(/* webpackChunkName: "home" */ '../views/home/Index'),
			},
			{
				path: '/topic',
				name: 'Topic',   //专题页
				component: () => import(/* webpackChunkName: "home" */ '../views/home/Topic'),
			}
			,
			{
				path: '/coupon',
				name: 'CouponCenter',   //领劵中心
				component: () => import(/* webpackChunkName: "home" */ '../views/home/couponCenter'),
			}
		],
	},
	{
		path: '/goods',
		name: 'Goods', //商品模块
		component: () => import(/* webpackChunkName: "home" */ '../views/goods/Header'),
		children: [
			{
				path: 'category',
				name: 'GoodsCategory',//商品分类页面
				component: () => import(/* webpackChunkName: "home" */ '../views/goods/Category'),
			},
			{
				path: 'list',
				name: 'GoodsList',
				component: () => import(/* webpackChunkName: "home" */ '../views/goods/GoodsList'),
			},
			{
				path: '/brand',
				name: 'Brand', //品牌中心
				component: () => import(/* webpackChunkName: "home" */ '../views/goods/Brand')
			},
			{
				path: 'series',
				name: 'Series', //样品系列详情
				component: () => import(/* webpackChunkName: "home" */ '../views/goods/Series')
			},
			{
				path: 'contrast',
				name: 'Contrast', //样品对比详情
				component: () => import(/* webpackChunkName: "home" */ '../views/goods/Contrast')
			},
			{
				path: 'detail',
				name: 'GoodsDetail',//商品详情页
				component: () => import(/* webpackChunkName: "home" */ '../views/goods/GoodsDetail'),
			},
			{
				path: 'new',
				name: 'new',//新品速递
				component: () => import(/* webpackChunkName: "home" */ '../views/goods/new'),
			}

		]
	},
	{
		path: '/member',
		name: 'Member',
		component: () => import(/* webpackChunkName: "home" */ '../views/member/Common'),
		redirect: '/member/index',
		children: [
			{
				path: '/member/index',
				name: 'MemberIndex',    //个人中心
				component: () => import(/* webpackChunkName: "home" */ '../views/member/Index'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/collect',
				name: 'MemberCollect',    //我的收藏
				component: () => import(/* webpackChunkName: "home" */ '../views/member/Collect'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/footprint',
				name: 'MemberFootprint',    //我的足迹
				component: () => import(/* webpackChunkName: "home" */ '../views/member/Footprint'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/address/list',
				name: 'memberAddressList',    //收货地址列表
				component: () => import(/* webpackChunkName: "home" */ '../views/member/AddressList'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/order/list',
				name: 'memberOrderList',    //我的订单
				component: () => import('../views/member/order/List'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/order/quoteOrder',
				name: 'memberQuoteList',    //询价订单
				component: () => import('../views/member/order/quoteOrder'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/order/refund/apply', //申请退款
				name: 'ApplyRefund',
				component: () => import('../views/member/order/ApplyRefund'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			}, {
				path: '/member/order/refund/detail', //售后详情
				name: 'RefundDetail',
				component: () => import(/* webpackChunkName: "home" */ '../views/member/order/RefundDetail'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/order/evaluation',
				name: 'MemberOrderEvaluation',    //评价订单列表
				component: () => import('../views/member/order/Evaluation'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/order/aftersales', //售后列表
				name: 'AfterSalesList',
				component: () => import(/* webpackChunkName: "home" */ '../views/member/order/AfterSalesList'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/order/logistics', //填写物流信息
				name: 'Logistics',
				component: () => import(/* webpackChunkName: "home" */ '../views/member/order/Logistics'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/sample/list',
				name: 'memberSampleList',    //样品订单
				component: () => import('../views/member/order/sampleList'),
				beforeEnter(to, from, next) { checkLogin(next, to, [2]); }
			},
			{
				path: '/member/project/list',
				name: 'memberProjectList',    //我的项目
				component: () => import('../views/member/order/projectList'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/project/detail',
				name: 'memberProjectDetail',    //项目详情
				component: () => import('../views/member/order/projectDetail'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/apply',
				name: 'memberDesignerApply',    //采购申请
				component: () => import('../views/member/designerApply'),
				beforeEnter(to, from, next) { checkLogin(next, to, [2]); }
			},
			{
				path: '/member/applyInfo',
				name: 'memberDesignerInfo',    //采购申请详情
				component: () => import('../views/member/designerApplyInfo'),
				beforeEnter(to, from, next) { checkLogin(next, to, [2]); }
			},
			{
				path: '/member/regular/list',
				name: 'memberRegularList',    //常购清单
				component: () => import('../views/member/order/regularList'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/coupon',
				name: 'MemberCoupon',    //我的优惠卷
				component: () => import('../views/member/money/Coupon'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/balance',
				name: 'MemberBalance',    //我的余额
				component: () => import('../views/member/money/Balance'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/account',
				name: 'MemberAccount',    //账号安全
				component: () => import('../views/member/center/Account'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/authen',
				name: 'MemberAuthen',    //企业认证
				component: () => import('../views/member/authentication'),
				beforeEnter(to, from, next) { checkLogin(next, to, [1, 3]); }
			},
			{
				path: '/member/phone',
				name: 'MemberPhoneMange',    //手机号管理
				component: () => import('../views/member/center/PhoneMange'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/email',
				name: 'memberEmailMange',    //邮箱管理
				component: () => import('../views/member/center/EmailMange'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/pwd/login',
				name: 'MemberLoginPassword',    //登陆密码
				component: () => import('../views/member/center/LoginPassword'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/pwd/pay',
				name: 'MemberPayPassword',    //支付密码
				component: () => import('../views/member/center/PayPassword'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/pwd/reset',
				name: 'MemberResetPassword',    //重置支付密码
				component: () => import('../views/member/center/ResetPassword'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/info',
				name: 'MemberInfo',    //会员信息
				component: () => import('../views/member/Info'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/recharge',
				name: 'MemberRecharge',    //充值
				component: () => import('../views/member/money/Recharge'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/myPoint',
				name: 'MyPoint',
				component: () => import('../views/member/myPoint'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/pointOrder/list',
				name: 'pointOrderList',
				component: () => import('../views/member/pointOrder/list'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/subAccount',
				name: 'subAccount',
				component: () => import('../views/member/subAccount/subAccount'),
				beforeEnter(to, from, next) { checkLogin(next, to, [3]); }
			},
			{
				path: '/member/createSub',
				name: 'createSub',
				component: () => import('../views/member/subAccount/subAccCreate'),
				beforeEnter(to, from, next) { checkLogin(next, to, [3]); }
			},
			{
				path: '/member/matCode',
				name: 'matCode',
				component: () => import('../views/member/matCode'),
				beforeEnter(to, from, next) { checkLogin(next, to, [3]); }
			},
			{
				path: '/member/desApp/list',
				name: 'desAppList',
				component: () => import('../views/member/desAppOrder/list'),
				beforeEnter(to, from, next) { checkLogin(next, to, [3]); }
			},
			{
				path: '/member/desApp/detail',
				name: 'desAppDetail',
				component: () => import('../views/member/desAppOrder/detail'),
				beforeEnter(to, from, next) { checkLogin(next, to, [3]); }
			},
			{
				path: '/member/permission',
				name: 'FinacePermission',//授信申请
				component: () => import('../views/member/finance/getPermission'),
				beforeEnter(to, from, next) { checkLogin(next, to, [1, 3]); }
			},
			{
				path: '/member/invoice',
				name: 'FinaceInvoice',//发票
				component: () => import('../views/member/finance/invoice'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/invoice/header',
				name: 'FinaceInvoiceHeader',//发票抬头
				component: () => import('../views/member/finance/invoiceHeader'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/invoice/address',
				name: 'FinaceInvoiceAddress',//发票收件地址
				component: () => import('../views/member/finance/invoiceAddress'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/invoice/add',
				name: 'FinaceInvoiceAdd',//开票
				component: () => import('../views/member/finance/invoiceAdd'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/invoice/info',
				name: 'FinaceInvoiceInfo',//开票
				component: () => import('../views/member/finance/invoiceInfo'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/contract/index',
				name: 'FinaceContract',//合同
				component: () => import('../views/member/contract/index'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/contract/detail',
				name: 'FinaceContractDetail',//查看合同
				component: () => import('../views/member/contract/detail'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/contract/add',
				name: 'FinaceContractAdd',//合同申请
				component: () => import('../views/member/contract/addContract'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/contract/authen',
				name: 'FinaceContractAuth',//合同认证申请
				component: () => import('../views/member/contract/authen'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/order/info',
				name: 'memberOrderInfo',//订单详情-新
				component: () => import('../views/member/order/info'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/request/info',
				name: 'memberRequestInfo',//样品申请订单详情
				component: () => import('../views/member/order/requestInfo'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/credit/index',
				name: 'memberCreditIndex',//信用账单
				component: () => import('../views/member/credit/index'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: '/member/article',
				name: 'memberArticleCollect',//文章收藏
				component: () => import('../views/member/articleCollect'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: 'inquiry/myQuo',
				name: 'myQuotation',//我的报价
				component: () => import('../views/member/inquiry/myQuo'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: 'inquiry/myInq',
				name: 'myInquiry',//我的询价
				component: () => import('../views/member/inquiry/myInq'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: 'inquiry/purchase',
				name: 'myPurchase',//采购询价
				component: () => import('../views/member/inquiry/purchase'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: 'inquiry/myCon',
				name: 'myContract',//合约订单
				component: () => import('../views/member/inquiry/myCon'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},

			{
				path: 'bid',
				name: 'memberBid',//我的招标
				component: () => import('../views/member/bid'),

			},

			{
				path: 'bidInvite',
				name: 'bidInvite',//我的招标
				component: () => import('../views/member/bidInvite'),
				beforeEnter(to, from, next) { checkLogin(next, to, [3]); }
			}

		]
	},
	{
		path: '/msg',
		name: 'Msg',
		component: () => import(/* webpackChunkName: "home" */ '../views/msg/Header'),//消息中心公共部分
		redirect: '/msg/list',
		children: [
			{
				path: 'list',
				name: 'MsgList',    //消息列表
				component: () => import(/* webpackChunkName: "home" */ '../views/msg/List'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: 'setting',
				name: 'MsgSetting',    //消息接收设置
				component: () => import(/* webpackChunkName: "home" */ '../views/msg/Setting'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
		]
	},
	{
		path: '/member/order/detail',
		name: 'OrderDetail',//订单详情
		component: () => import(/* webpackChunkName: "home" */ '../views/member/Header'),
		redirect: '/member/order/detail',
		children: [
			{
				path: '/member/order/detail',
				name: 'memberOrderDetail',    //订单详情
				component: () => import(/* webpackChunkName: "home" */ '../views/member/order/detail'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
		]
	},
	{
		path: '/member/order/evaluate',
		name: 'OrderEvaluate',//订单评价
		component: () => import(/* webpackChunkName: "home" */ '../views/member/Header'),
		redirect: '/member/order/evaluate',
		children: [
			{
				path: '/member/order/evaluate',
				name: 'memberOrderEvaluate',    //订单评价
				component: () => import(/* webpackChunkName: "home" */ '../views/member/order/Evaluate'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
		]
	},
	{
		path: '/login',
		name: 'Login',//用户登录页面
		component: () => import(/* webpackChunkName: "home" */ '../views/member/login/Index'),
	},
	{
		path: '/pwd/forget',
		name: 'ForgetPassword',//用户找回密码页面
		component: () => import(/* webpackChunkName: "home" */ '../views/member/login/ForgetPassword'),
	},
	{
		path: '/register',
		name: 'register',//用户注册页面
		component: () => import(/* webpackChunkName: "home" */ '../views/member/login/Register'),
	},
	{
		path: '/agreement',
		name: 'agreement',//用户注册协议页面
		component: () => import(/* webpackChunkName: "home" */ '../views/member/login/Agreement'),
	},
	{
		path: '/bindMobile',
		name: 'bindMobile',//用户注册协议页面
		component: () => import(/* webpackChunkName: "home" */ '../views/member/login/bindMobile'),
	},
	{
		path: '/buy',
		name: 'Buy',//下单页入口
		component: () => import(/* webpackChunkName: "home" */ '../views/buy/Header'),
		children: [
			{
				path: 'confirm',
				name: 'BuyConfirmOrder',//下单页面
				component: () => import('../views/buy/ConfirmOrder'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: 'request',
				name: 'ConfirmRequest',//下单页面
				component: () => import('../views/buy/ConfirmRequest'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: 'pay',
				name: 'Pay',//支付页面
				component: () => import('../views/buy/pay'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
		]
	},
	{
		path: '/information',
		name: 'information',//文章页面
		component: () => import(/* webpackChunkName: "home" */ '../views/information/Header'),
		redirect: '/index',
		children: [
			{
				path: '/information',
				name: 'informationIndex',//
				component: () => import(/* webpackChunkName: "home" */ '../views/information/index'),
			},
			{
				path: '/information/list',
				name: 'informationList',//
				component: () => import(/* webpackChunkName: "home" */ '../views/information/list'),
			},
			{
				path: '/information/detail',
				name: 'informationDetail',//
				component: () => import(/* webpackChunkName: "home" */ '../views/information/detail'),
			}
		]
	},
	{
		path: '/store',
		name: 'store',
		component: () => import(/* webpackChunkName: "home" */ '../views/store/Header'),
		children: [
			{
				path: 'list',
				name: 'storeSwiper',//店铺列表
				component: () => import(/* webpackChunkName: "home" */ '../views/store/storeSwiper'),
			},
			{
				path: 'list_old',
				name: 'StoreList',//店铺列表模块
				component: () => import(/* webpackChunkName: "home" */ '../views/store/storeList'),
			}
		]
	},
	{
		path: '/store/detail',
		name: 'StoreDetail',//店铺模块
		component: () => import(/* webpackChunkName: "home" */ '../views/store/StoreHeader'),
		redirect: '/store/index',
		children: [
			{
				path: '/store/index',
				name: 'storeIndex',//店铺首页
				component: () => import(/* webpackChunkName: "home" */ '../views/store/Index'),
			},
			{
				path: '/store/goods',
				name: 'storeGoods',//店铺商品列表
				component: () => import(/* webpackChunkName: "home" */ '../views/store/GoodsList'),
			},
			{
				path: '/store/product',
				name: 'storeProduct',//店铺商品列表
				component: () => import(/* webpackChunkName: "home" */ '../views/store/productList'),
			},
			{
				path: '/store/new',
				name: 'storeNewProduct',//店铺商品列表
				component: () => import(/* webpackChunkName: "home" */ '../views/store/newProduct'),
			},
			{
				path: '/store/article/list',
				name: 'storeArticleList',//行业知识
				component: () => import(/* webpackChunkName: "home" */ '../views/store/articleList'),
			},
			{
				path: '/store/news',
				name: 'storeNews',//企业动态
				component: () => import(/* webpackChunkName: "home" */ '../views/store/news'),
			},
			{
				path: '/store/news/info',
				name: 'storeNewsInfo',//企业动态详情
				component: () => import(/* webpackChunkName: "home" */ '../views/store/newsInfo'),
			},
			{
				path: '/store/introduce',
				name: 'storeIntroduce',//企业介绍
				component: () => import(/* webpackChunkName: "home" */ '../views/store/introduce'),
			}
		]
	},
	{
		path: '/cart',
		name: 'Cart', //商品模块
		component: () => import(/* webpackChunkName: "home" */ '../views/cart/Header'),
		children: [
			{
				path: 'index',
				name: 'CartIndex',    //购物车页面
				component: () => import(/* webpackChunkName: "home" */ '../views/cart/CartIndex'),
			},
			{
				path: 'request',
				name: 'RequestForm',    //样品申请单
				component: () => import(/* webpackChunkName: "home" */ '../views/cart/requestForm'),
			}
		]
	},
	{
		path: '/point',
		name: 'Point',//积分商城
		component: () => import(/* webpackChunkName: "home" */ '../views/point/pointIndex/Header'),
		redirect: '/point/index',
		children: [
			{
				path: 'index',
				name: 'PointIndex',//积分首页
				component: () => import(/* webpackChunkName: "home" */ '../views/point/pointIndex/Index'),
			},
			{
				path: 'list',
				name: 'PointList',//积分商品列表
				component: () => import(/* webpackChunkName: "home" */ '../views/point/pointIndex/List'),
			}
		]
	},
	{
		path: '/point/goods',
		name: 'PointDetail',//积分商品详情
		component: () => import(/* webpackChunkName: "home" */ '../views/point/goods/Header'),
		redirect: '/point/detail',
		children: [
			{
				path: '/point/detail',
				name: 'detail',//积分商品详情
				component: () => import(/* webpackChunkName: "home" */ '../views/point/goods/Detail'),
			}
		]
	},
	{
		path: '/point/order/confirm',
		name: 'PointOrder',//积分确认订单
		component: () => import(/* webpackChunkName: "home" */ '../views/point/exchange/ConfirmOrder'),
		beforeEnter(to, from, next) { checkLogin(next, to); }
	},
	{
		path: '/point/pay',
		name: 'PointPay',//积分支付页面
		component: () => import('../views/point/exchange/pay'),
		beforeEnter(to, from, next) { checkLogin(next, to); }
	},
	{
		path: '/member/pointOrder/detail',
		name: 'PointOrderPay',//积分订单详情
		component: () => import(/* webpackChunkName: "home" */ '../views/member/Header'),
		redirect: '/member/pointOrder/detail',
		children: [
			{
				path: '/member/pointOrder/detail',
				name: 'memberPointOrderPay', //积分订单详情
				component: () => import(/* webpackChunkName: "home" */ '../views/member/pointOrder/detail'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
		]
	},
	{
		path: '/service',
		name: 'serviceChat',
		component: () => import('../views/service/chatPage'),
		beforeEnter: (to, from, next) => { checkLogin(next, to); }
	},
	{
		path: '/prototype',
		name: 'prototype',
		component: () => import('../views/prototype/Header'),
		children: [
			{
				path: '/quickOrder',
				name: 'quickOrder',
				component: () => import('../views/prototype/quickOrder'),
				beforeEnter: (to, from, next) => { checkLogin(next, to); }
			},
			{
				path: 'optimize/index',
				name: 'optimize',
				component: () => import('../views/prototype/optimize/optimize'),
				beforeEnter: (to, from, next) => { checkLogin(next, to); }
			},
			{
				path: 'optimize/history',
				name: 'optiHistory',
				component: () => import('../views/prototype/optimize/optiHistory'),
				beforeEnter: (to, from, next) => { checkLogin(next, to); }
			},
			{
				path: 'optimize/hisDetail',
				name: 'hisDetail',
				component: () => import('../views/prototype/optimize/hisDetail'),
				beforeEnter: (to, from, next) => { checkLogin(next, to); }
			},
			{
				path: 'replacement/search',
				name: 'reSearch',
				component: () => import('../views/prototype/replacement/search'),
			},
			{
				path: 'replacement/list',
				name: 'reList',
				component: () => import('../views/prototype/replacement/list'),
			},
			{
				path: 'sample/apply',
				name: 'sampleApply',
				component: () => import('../views/prototype/sample/apply'),
			},
			{
				path: 'sample/history',
				name: 'sampleHistory',
				component: () => import('../views/prototype/sample/history'),
			},
			{
				path: 'sample/detail',
				name: 'sampleDetail',
				component: () => import('../views/prototype/sample/detail'),
			}
		],
	},

	{
		path: '/member/credit/pay',
		name: 'memberCreditPay',//信用账单支付
		component: () => import('../views/member/credit/pay'),
		beforeEnter(to, from, next) { checkLogin(next, to); }
	},
	{
		path: '/article',
		name: 'article',//文章页面
		component: () => import(/* webpackChunkName: "home" */ '../views/article/article'),
	},
	{
		path: '/contract/agreement',
		name: 'FinaceContractAgreement',//合同认证协议
		component: () => import('../views/member/contract/agreement'),
		beforeEnter(to, from, next) { checkLogin(next, to); }
	},
	{
		path: '/applyDesigner',
		name: 'ApplyDesigner',//设计师认证页
		component: () => import('../views/member/authenDesigner'),
		beforeEnter(to, from, next) { checkLogin(next, to); }
	},
	{
		path: '/previewFile',
		name: 'previewFile',//文件预览
		component: () => import('../views/viewFile'),
		beforeEnter(to, from, next) { checkLogin(next, to); }
	},
	{
		path: '/inquiry',
		name: 'inquiry',//询价
		component: () => import(/* webpackChunkName: "home" */ '../views/inquiry/header'),
		children: [
			{
				path: 'index',
				name: 'inquiry_index',
				component: () => import('../views/inquiry/index')
			},
			{
				path: 'supplier/init_contract',
				name: 'init_contract',//供应方方发起合约
				component: () => import('../views/inquiry/supplier/init_contract')
			},
			{
				path: 'purchaser/init_inquiry',
				name: 'init_inquiry',//采购方发起询盘
				component: () => import('../views/inquiry/purchaser/init_inquiry')
			},
			{
				path: 'purchaser/quo_info',
				name: 'purchaser_quo_info',//采购方的报价详情 
				component: () => import('../views/inquiry/purchaser/quo_info'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},

			{
				path: 'contract_quo',
				name: 'contract_quo',//合约里的报价详情
				component: () => import('../views/inquiry/contract_quo'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: 'supplier/inq_info',
				name: 'supplier_inq_info',//供应方的询盘详情
				component: () => import('../views/inquiry/supplier/inq_info'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},

			{
				path: 'purchaser/inq_info',
				name: 'purchaser_inq_info',//采购方的询盘详情
				component: () => import('../views/inquiry/purchaser/inq_info'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},

			{
				path: 'supplier/quo_info',
				name: 'supplier_quo_info',//供应商的报价详情
				component: () => import('../views/inquiry/supplier/quo_info'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: 'supplier/contract_info',
				name: 'supplier_contract_info',//供应商合约详情
				component: () => import('../views/inquiry/supplier/contract_info'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: 'purchaser/contract_info',
				name: 'purchaser_contract_info',//供应商合约详情
				component: () => import('../views/inquiry/purchaser/contract_info'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: 'present',
				name: 'myPresent',//提交采购询价
				component: () => import('../views/inquiry/present'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: 'logistics',
				name: 'myLogistics',//物流费用计算
				component: () => import('../views/inquiry/logistics'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},


		]
	},

	{
		path: '/bid',
		name: 'bid',//招标
		component: () => import(/* webpackChunkName: "home" */ '../views/bid/Header'),
		children: [
			{
				path: 'openBid',
				name: 'openBid',//发布招标
				component: () => import('../views/bid/openBid'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: 'winBid',
				name: 'winBid',//发布中标
				component: () => import('../views/bid/winBid'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: 'terBid',
				name: 'terBid',//发布终止公告
				component: () => import('../views/bid/terBid'),
				beforeEnter(to, from, next) { checkLogin(next, to); }
			},
			{
				path: 'winbidNoti',
				name: 'winbidNoti',//中标详情
				component: () => import('../views/bid/winbidNoti'),
			},
			{
				path: 'terbidNoti',
				name: 'terbidNoti',//终止公告
				component: () => import('../views/bid/terBidNoti'),
			},
			{
				path: 'index',
				name: 'bid_index',//供应商合约详情
				component: () => import('../views/bid/index'),
			},
			{
				path: 'more',
				name: 'bid_more',//供应商合约详情
				component: () => import('../views/bid/bidMore'),
			},
			{
				path: 'more_terBid',
				name: 'more_terBid',//供应商合约详情
				component: () => import('../views/bid/more_terBid'),
			},
			{
				path: 'detail',
				name: 'bidDetail',//供应商合约详情
				component: () => import('../views/bid/detail'),
			},
		]
	},
	{
		path: '/promotion',
		name: 'promotion',//招标
		component: () => import(/* webpackChunkName: "home" */ '../views/promotion/header'),
		children: [
			{
				path: 'seckill',
				name: 'seckill',//发布招标
				component: () => import('../views/promotion/seckill'),
			},

		]
	},

]

const router = createRouter({
	history: createWebHistory(),
	routes
})

router.beforeEach((to, from, next) => {
	let code = to.query.code
	let state = to.query.state
	if (code && state) {
		wetChatLogin(to, next)
	} else {
		next()
	}
	NProgress.start();
})

router.afterEach((to, from) => {
	window.document.title = title;
	let url = apiUrl.substring(0, apiUrl.length - 1);
	//商品详情页、店铺的页面需要单独统计，但是需要把pageUrl和referrerPageUrl先存进去
	let specialPages = [
		'/goods/detail',//商品详情页
		'/store/product',//店铺首页
		// '/store/goods',//店铺首页
	];
	let statPvFlag = true;
	for (let i in specialPages) {
		if (specialPages[i].indexOf(to.path) > -1) {
			statPvFlag = false;
			break;
		}
	}

	if (!statPvFlag) {
		//不需要pv类型的统计
		updateStatCommonProperty({ pageUrl: url + to.path, referrerPageUrl: url + from.path });
	} else {
		setTimeout(() => {
			sldStatEvent({ behaviorType: 'pv', pageUrl: url + to.path, referrerPageUrl: url + from.path });
		}, 3000)
	}

	NProgress.done();
})

export default router
