<template>
  <router-view />
  <fixedTab></fixedTab>
  <loading v-show="isLoading && isShow"></loading>
</template>

<script>
  import fixedTab from "@/components/fixedTab.vue";
  import loading from "@/components/loading.vue";
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';

  export default {
    name: 'App',
    components: {
      fixedTab,
      loading
    },
    setup() {
      const router = useRouter();
      const isLoading = ref(false);
      const isShow = ref(true);

      router.beforeEach((to) => {
        if (to.path == '/member/order/list') {
          isShow.value = false;
        } else {
          isShow.value = true;
        }
        isLoading.value = true;
      });

      router.afterEach(() => {
        isLoading.value = false;
      });

      return { isLoading, isShow }
    }
  }
</script>

<style lang="scss">
  @import "./style/reset.scss";
  @import "./style/base.scss";



  //放大镜位置
  .mouse-cover-canvas {
    position: absolute;
    top: 173px !important;
    left: 740px !important;
  }

  .sld_pagination {
    margin-top: 20px;
  }

  //顶部公共进度条颜色
  #nprogress .bar {
    background: #2f6eff !important;
  }


  .need_inquire {
    /* width: 64px !important;
    height: 19px !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &.type1 {
      background-image: url('./assets/need_inquire1.png');

    }

    &.type2 {
      background-image: url('./assets/need_inquire2.png');

    } */
  }
</style>